<template>
  <dt :class="config.size_value">
    <span v-if="!config.form.active || config.fconfig.readonly">{{
      config.form.formData[config.fconfig.translate][config.form.cur_lang][
        config.fname
      ]
    }}</span>
    <div v-if="config.form.active && !config.fconfig.readonly">
      <div v-for="(lang, index) in languages" :key="lang.language">
        <CInput
          :description="
            index == languages.length - 1 ? config.fconfig.description : null
          "
          horizontal
          v-model="
            config.form.formData[config.fconfig.translate][lang.language][
              config.fname
            ]
          "
          :is-valid="
            config.is_valid(
              config.fconfig.translate +
                '.' +
                lang.language +
                '.' +
                config.fname
            )
          "
          :invalid-feedback="
            config.invalid_feedback(
              config.fconfig.translate +
                '.' +
                lang.language +
                '.' +
                config.fname
            )
          "
        >
          <template #prepend-content><CIcon :content="lang.flag" /></template>
        </CInput>
      </div>
    </div>
  </dt>
</template>

<script>
import { languages } from "../../config/global";
export default {
  name: "FieldTransText",
  props: ["config"],
  data() {
    return {
      languages,
    };
  },
};
</script>
