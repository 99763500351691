var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dt',{class:_vm.config.size_value},[(!_vm.config.form.active || _vm.config.fconfig.readonly)?_c('span',[_vm._v(_vm._s(_vm.config.form.formData[_vm.config.fconfig.translate][_vm.config.form.cur_lang][ _vm.config.fname ]))]):_vm._e(),(_vm.config.form.active && !_vm.config.fconfig.readonly)?_c('div',_vm._l((_vm.languages),function(lang,index){return _c('div',{key:lang.language},[_c('CInput',{attrs:{"description":index == _vm.languages.length - 1 ? _vm.config.fconfig.description : null,"horizontal":"","is-valid":_vm.config.is_valid(
            _vm.config.fconfig.translate +
              '.' +
              lang.language +
              '.' +
              _vm.config.fname
          ),"invalid-feedback":_vm.config.invalid_feedback(
            _vm.config.fconfig.translate +
              '.' +
              lang.language +
              '.' +
              _vm.config.fname
          )},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"content":lang.flag}})]},proxy:true}],null,true),model:{value:(
          _vm.config.form.formData[_vm.config.fconfig.translate][lang.language][
            _vm.config.fname
          ]
        ),callback:function ($$v) {_vm.$set(_vm.config.form.formData[_vm.config.fconfig.translate][lang.language], 
            _vm.config.fname
          , $$v)},expression:"\n          config.form.formData[config.fconfig.translate][lang.language][\n            config.fname\n          ]\n        "}})],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }